<template>
  <div class="container-contratos mb-2 h-100">
    <div class="card-status h-100">
      <div
        :class="
          cor ? 'bg-gradient-success' : 'bg-gradient-danger'
        "
        class="icon icon-shape icon-lg shadow text-center border-radius-lg text-white"
      >
        <i class="opacity-10" :class="icon" aria-hidden="true"></i>
      </div>

      <div class="mx-3 informacoes">
        <p class="mb-0 text-center text-bold">{{ text }}</p>
        <h6 class="mb-0 text-bold">{{ value }}</h6>
      </div>
    </div>
  </div>
</template>

<script>
import SoftAvatar from "@/components/SoftAvatar.vue";
import img from "@/assets/img/curved-images/curved14.jpg";
import img1 from "@/assets/img/logos/mastercard.png";
import logo from "@/assets/img/logo.png";

export default {
  name: "CardLoginPremium",
  components: {
    SoftAvatar,
  },
  props: {
    login: Object,
    icon: String,
    text: String,
    cor: Boolean,
    value: {
      type: [String, Number],
      default: "",
    },
  },
  data() {
    return {
      img,
      img1,
      logo,
    };
  },
};
</script>

<style scoped>
.container-contratos {
  display: flex;
  margin: 0 auto;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
}

.card-status {
  background-color: #fff;
  padding: 20px;
  width: 100%;
  display: flex;
  box-shadow: 0 20px 27px 0 rgba(0, 0, 0, 0.05);
  border: 0 solid rgba(0, 0, 0, 0.125);
  border-radius: 1rem;
  align-items: center;
}

.badge-contrato {
  color: #fff;
}

.badge-contrato #badge-internet {
  max-width: auto;
  background-color: rgba(79, 148, 239, 0.2);
  color: rgb(79, 148, 239);
  padding: 2px 6px;
  border-radius: 12px;
}

.card-status p {
  margin-bottom: 0;
}

.informacoes {
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: auto;
}
</style>
