<template>
  <div class="p-3 pb-0 card-header">
    <div class="row justify-content-between">
      <div class="col-12">
        <h6 class="mb-0">Monitoramento - ICMP</h6>
        <p class="text-sm">Últimos 7 dias</p>
      </div>
    </div>
  </div>
  <div class="p-3 card-body" ref="boxGrafico"
    :class="exibeGrafico == false ? 'd-flex justify-content-center align-items-center' : ''"
    style="overflow-y:auto; min-height: 244px;">
    <GraficoConexaoLogin v-if="exibeGrafico" :larguragrafico="largura" :data="dadosConexao" />
    <div v-else>
      Selecione um login no botão acima!
    </div>
  </div>
  <Loading :active='isLoading' :is-full-page="fullPage" :loader='loader' :color="colorLoading" />
</template>

<script>
import GraficoConexaoLogin from "../components/GraficoConexaoLogin.vue";
import { apimonitoramento } from '../../boot/axios';
import { ref } from 'vue';
import Loading from 'vue3-loading-overlay';
import 'vue3-loading-overlay/dist/vue3-loading-overlay.css';
import { useLoginStore } from "../../stores/login";
let LoginStore;

export default {
  props: {
    largura: String,
    login: String
  },
  components: {
    Loading,
    GraficoConexaoLogin,
  },
  data() {
    return {
      LoginStore,
      LoginSelecionado: undefined,
      exibeGrafico: false,
      dadosConexao: [],
    }
  },
  async mounted() {
    await this.BuscarDadosConexao();
  },
  methods: {
    async BuscarDadosConexao() {
      if (this.login != undefined) {
        await apimonitoramento
          .post("zabbix/grafico", { PppoeLogin: this.login })
          .then(async (response) => {
            if (response.data.status) {
              this.dadosConexao = response.data.Grafico.map(item => {
                return { Horario: item.clock, Valor: item.value };
              });

              this.exibeGrafico = true;
            }
          })
          .catch((error) => {
            console.log(error)
          })
      }
    },
  },
  watch: {
    async login() {
      this.isLoading = true;
      this.exibeGrafico = false;
      await this.BuscarDadosConexao(this.login);
      this.exibeGrafico = true;
      this.isLoading = false;
    }
  },
  setup() {
    LoginStore = useLoginStore();
    let isLoading = ref(false)
    let fullPage = true
    let loader = 'spinner'
    let colorLoading = '#007BFF'

    return {
      isLoading,
      fullPage,
      loader,
      colorLoading,
    }
  }
}
</script>