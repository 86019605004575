<template>
  <div class="container-contratos mb-2">
    <div class="card">
      <div class="badge-contrato mb-3">
        <div id="badge-internet" class="d-flex align-items-center">
          <i class="bi bi-wifi" style="margin-right: 6px !important"></i>
          <p class="text-sm text-bold">Internet</p>
        </div>
      </div>
      <div>
        <p class="text-md text-bold">{{ login.ip }}</p>
        <p class="text-xs text-bold" v-if="login.pd_ipv6 != ''">
          IPv6: {{ login.pd_ipv6 }}
        </p>
        <p class="text-xs text-bold">PPPoE: {{ login.login }}</p>
        <p class="text-xs text-bold">Login ID: {{ login.id }}</p>
        <p v-if="login.endereco != ''" class="text-xs text-bold mt-1">
          {{ login.endereco }} - Nº{{ login.numero }}
        </p>
        <div
          class="d-flex align-items-center mt-1"
          :class="login.ativo == 'S' ? 'text-success' : 'text-body'"
        >
          <i
            :class="login.ativo == 'S' ? 'bi-emoji-smile' : 'bi-emoji-frown'"
            class="bi me-1 text-xs"
          ></i>
          <p class="text-xs text-bold">
            {{
              login.ativo == "S" ? "Ativo" : login.ativo == "N" ? "Inativo" : ""
            }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SoftAvatar from "@/components/SoftAvatar.vue";
import img from "@/assets/img/curved-images/curved14.jpg";
import img1 from "@/assets/img/logos/mastercard.png";
import logo from "@/assets/img/logo.png";

export default {
  name: "CardLoginPremium",
  components: {
    SoftAvatar,
  },
  props: {
    login: Object,
  },
  data() {
    return {
      img,
      img1,
      logo,
    };
  },
};
</script>

<style scoped>
.container-contratos {
  display: flex;
  flex-wrap: wrap;
  gap: 10px 20px;
  margin: 0 auto;
  box-sizing: border-box;
}

.card {
  background-color: #fff;
  padding: 20px;
  width: 100%;
}

.badge-contrato {
  color: #fff;
}

.badge-contrato #badge-internet {
  max-width: auto;
  background-color: rgba(79, 148, 239, 0.2);
  color: rgb(79, 148, 239);
  padding: 2px 6px;
  border-radius: 12px;
}

.card p {
  margin-bottom: 0;
}
</style>
