<template>
  <div class="card" @click="ContatoWhatsapp">
    <div class="p-3 mx-4 text-center card-header d-flex justify-content-center">
      <div
        :class="
          typeof icon === 'object' ? icon.background : 'bg-gradient-success'
        "
        class="icon icon-shape icon-lg shadow text-center border-radius-lg"
      >
        <i
          class="opacity-10"
          :class="typeof icon === 'string' ? icon : icon.component"
          aria-hidden="true"
        ></i>
      </div>
    </div>
    <div class="p-3 pt-0 text-center card-body">
      <h6 class="mb-0 text-center">{{ title }}</h6>
      <span class="text-xs">{{ description }}</span>
      <hr class="my-3 horizontal dark" />
      <button
        class="btn btn-link text-dark text-sm mb-0 px-0"
        style="padding: 0"
      >
        <i class="bi bi-whatsapp"></i>
        (51) 3191-2777
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "DefaultInfoCard",
  props: {
    icon: {
      type: [String, Object],
      required: true,
      component: {
        type: String,
      },
      background: {
        type: String,
      },
      default: () => ({
        background: "bg-white",
      }),
    },
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      default: "",
    },
    value: {
      type: [String, Number],
      default: "",
    },
  },
  methods: {
    ContatoWhatsapp() {
      window.open("https://api.whatsapp.com/send?phone=555131912777", "_blank");
    },
  },
};
</script>

<style scoped>
.card:hover {
  cursor: pointer;
}
</style>
