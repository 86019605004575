<template>
  <div ref="chart" :width="larguragrafico" style="height: 300px"></div>
</template>

<script>
import { ref, onMounted } from 'vue';
import * as echarts from 'echarts';

export default {
  name: 'GraficoConexaoLogin',
  props: ['data', 'larguragrafico'],
  setup(props) {
    const chart = ref(null);

    onMounted(() => {
      const myChart = echarts.init(chart.value);

      let Horarios = props.data.map((item) => {
        return item.Horario
      })

      let Valores = props.data.map((item) => {
        return item.Valor
      })

      myChart.setOption({
        visualMap: {
          show: false,
          top: 0,
          right: 20,
          pieces: [
            {
              gt: 0,
              lte: 1,
              color: 'rgb(19, 158, 68)'
            },
            {
              gt: 1,
              lte: 100,
              color: 'rgb(144, 0, 20)'
            },
          ],
          outOfRange: {
            color: 'rgb(19, 158, 68)'
          }
        },
        tooltip: {
          trigger: 'axis',
          position: function (pt) {
            return [pt[0], '10%'];
          }
        },
        title: {
          left: 'center',
          text: 'Monitoramento ICMP'
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: Horarios
        },
        yAxis: {
          type: 'value',
          boundaryGap: [0, '100%']
        },
        dataZoom: [
          {
            type: 'inside',
            start: 80,
            end: 100
          },
          {
            start: 80,
            end: 100,
            type: 'slider',
          },
        ],
        series: [
          {
            name: 'ICMP Loss',
            type: 'line',
            symbol: 'none',
            sampling: 'lttb',
            itemStyle: {
              color: Valores[Valores.length - 1] == 0 ? 'rgb(19, 158, 68)' : 'rgb(144, 0, 20)'
            },
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 3, [
                {
                  offset: 0,
                  color: 'rgb(144, 0, 20)'
                },
                {
                  offset: 1,
                  color: 'rgb(95, 158, 68)'
                }
              ])
            },
            data: Valores
          }
        ]
      });
    });

    return { chart };
  },
};
</script>
