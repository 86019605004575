<template>
  <Loading
    :active="isLoading"
    :is-full-page="fullPage"
    :loader="loader"
    :color="colorLoading"
  />
  <div class="container-fluid mt-4">
    <div class="row">
      <div class="col-lg-8 mb-lg-0 mb-4">
        <div class="card h-100">
          <div class="card-body p-3 h-100">
            <div class="row px-2 py-2 h-100">
              <div class="col-lg-6">
                <div class="d-flex flex-column h-100">
                  <h5 class="font-weight-bolder pt-4">Monitoramento Premium</h5>
                  <p class="mb-5">
                    Selecione o login para verificar o relatório completo das
                    suas conexões, consumo diário, tempo conectado e muito mais!
                  </p>
                  <select
                    class="form-select"
                    v-model="LoginSelecionado"
                    aria-label="Default select example"
                  >
                    <option :value="undefined" selected>
                      Selecionar Login
                    </option>
                    <option
                      :value="login.login"
                      :key="login.id"
                      v-for="login in LoginStore.getLogins()"
                    >
                      {{ login.id }} - {{ login.login }}
                    </option>
                  </select>
                </div>
              </div>
              <div
                class="col-lg-5 bg-cover ms-auto mt-lg-0 border-radius-lg"
                :style="{
                  backgroundImage:
                    'url(' +
                    require('@/assets/img-minhastar/pessoa-adulta-trabalhando-tarde-da-noite-em-casa.jpg') +
                    ')',
                }"
              ></div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-4" v-if="!mobile || MonitoramentoAtivo == false">
        <div class="card h-100 p-3">
          <div
            class="overflow-hidden position-relative border-radius-lg bg-cover h-100"
            :style="{
              backgroundImage:
                'url(' +
                require('@/assets/img-minhastar/mulher-adulta-trabalhando-tarde-da-noite-em-casa.jpg') +
                ')',
            }"
          >
            <span class="mask bg-gradient-dark"></span>
            <div class="card-body position-relative z-index-1 p-3 h-100">
              <div class="d-flex flex-column h-100">
                <h5 class="text-white font-weight-bolder mb-4 pt-2">
                  Entre em contato para maiores informações
                </h5>
                <p class="text-white mb-5">
                  Relatório completo das suas conexões, consumo diário, tempo
                  conectado e muito mais!
                </p>
                <a
                  class="text-white font-weight-bold ps-1 mb-0 icon-move-left mt-auto"
                  target="_blank"
                  href="https://api.whatsapp.com/send?phone=555127472747&text=Ol%C3%A1%21+Gostaria+de+mais+informa%C3%A7%C3%B5es+sobre+o+monitoramento+de+logins"
                >
                  Saiba mais
                  <i
                    class="fas fa-arrow-right text-sm ms-1"
                    aria-hidden="true"
                  ></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="MonitoramentoAtivo">
      <div class="row">
        <div class="col-lg-12">
          <div class="row mt-4">
            <div class="col-xl-12">
              <div class="row">
                <div class="col-md-3 mb-3">
                  <CardLoginPremium :login="Login" />
                </div>
                <div class="col-md-3 mb-3">
                  <CardNovoConexao
                    :login="Login"
                    :cor="Login?.online == 'S' ? true : false"
                    :value="Login?.online == 'S' ? 'Online' : 'Offline'"
                    :text="'Status conexao'"
                    :icon="'fas fa-wifi'"
                  />
                </div>
                <div class="col-md-3 mb-3">
                  <CardNovoConexao
                    :login="Login"
                    :value="TempoOnline"
                    :cor="TempoOnline != '0h 0m' ? true : false"
                    :text="'Tempo Online'"
                    :icon="'fas fa-clock'"
                  />
                </div>
                <div class="col-md-3 mb-3">
                  <CardContatoDoSuporte
                    :text="'Contato Suporte'"
                    :icon="'fab fa-whatsapp'"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-8 flex mt-3">
          <div class="card z-index-2 mb-3">
            <BoxMonitoramento
              :login="LoginSelecionado"
              :largura="tamanhoBoxMonitoramento"
            />
          </div>
          <div class="card z-index-2 mb-3">
            <BoxConsumo :login="LoginId" />
          </div>
        </div>
        <div class="col-lg-4 mt-3" style="max-height: 850px !important">
          <Eventos :login="LoginSelecionado" />
        </div>
      </div>

      <div class="row d-none">
        <div class="col-lg-12 mt-3">
          <CardContatoSuporte
            icon="text-white fab fa-whatsapp"
            title="Suporte"
            description="Contato"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import BoxEventos from "./components/BoxEventos.vue";
import BoxConsumo from "./components/BoxConsumo.vue";
import BoxMonitoramento from "./components/BoxMonitoramento.vue";
import CardLogin from "./components/CardLogin.vue";
import CardContatoSuporte from "./components/CardContatoSuporte.vue";
import CardTempoOnline from "./components/CardTempoOnline.vue";
import CardOnline from "./components/CardOnline.vue";
import CardLoginPremium from "./components/CardLoginPremium.vue";
import CardNovoConexao from "./components/CardNovoConexao.vue";
import CardContatoDoSuporte from "./components/CardContatoDoSuporte.vue";
import { apimonitoramento } from "../boot/axios";
import { useAuthStore } from "../stores/auth";
import { useFinanceiroStore } from "../stores/financeiro";
import { useContratosStore } from "../stores/contratos";
import { useLoginStore } from "../stores/login";
import Loading from "vue3-loading-overlay";
import "vue3-loading-overlay/dist/vue3-loading-overlay.css";
let FinanceiroStore;
let ContratosStore;
let AuthStore;
let LoginStore;

export default {
  name: "Monitoramento",
  emits: ["click"],
  components: {
    CardLogin,
    CardContatoSuporte,
    BoxMonitoramento,
    BoxConsumo,
    CardTempoOnline,
    CardOnline,
    Eventos: BoxEventos,
    Loading,
    CardLoginPremium,
    CardNovoConexao,
    CardContatoDoSuporte,
  },
  data() {
    return {
      ContratosStore,
      tamanhoBoxMonitoramento: "0",
      AuthStore,
      FinanceiroStore,
      LoginStore,
      Login: undefined,
      LoginSelecionado: undefined,
      LoginId: undefined,
      exibeGrafico: false,
      chartData: [],
      xAxisData: [],
      dadosConexao: undefined,
      TempoOnline: undefined,
      MonitoramentoAtivo: false,
      isLoading: false,
      fullPage: true,
      loader: "spinner",
      colorLoading: "#007BFF",
      mobile: false,
    };
  },
  methods: {
    async BuscarLogin() {
      return await apimonitoramento
        .post("logins/buscalogin", { Login: this.LoginSelecionado })
        .then(async (response) => {
          if (response.data.status) {
            return true;
          } else {
            this.$notify({
              type: "error",
              text:
                "O monitoramento não está ativo para este login. Para mais informações, entre em contato conosco! (51) 2747-2747",
              duration: 8000,
            });
            return false;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async AtualizaInformacoes() {
      if (this.LoginSelecionado != undefined) {
        let monitoramento = await this.BuscarLogin();
        if (monitoramento) {
          this.Login = LoginStore.getLogins().find(
            (login) => login.login == this.LoginSelecionado
          );
          this.LoginId = this.Login.id;
          this.TempoOnline = this.converterOnline(this.Login.tempo_conectado);
          this.MonitoramentoAtivo = true;
          this.mobile = window.innerWidth < 767 ? true : false;
        } else {
          this.MonitoramentoAtivo = false;
        }
      } else {
        this.MonitoramentoAtivo = false;
      }
    },
    converterOnline(segundos) {
      const horas = Math.floor(segundos / 3600);
      const minutos = Math.floor((segundos % 3600) / 60);

      if (horas > 24) {
        const dias = Math.floor(horas / 24);
        const horasRestantes = horas % 24;
        return `${dias}d ${horasRestantes}h`;
      }

      return `${horas}h ${minutos}m`;
    },
  },
  watch: {
    async LoginSelecionado() {
      this.isLoading = true;
      this.exibeGrafico = false;
      await this.AtualizaInformacoes();
      this.exibeGrafico = true;
      this.isLoading = false;
    },
  },
  setup() {
    ContratosStore = useContratosStore();
    AuthStore = useAuthStore();
    FinanceiroStore = useFinanceiroStore();
    LoginStore = useLoginStore();
  },
};
</script>

<style scoped>
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 5px;
}

html {
  scrollbar-color: #888 #f1f1f1;
  scrollbar-width: thin;
}

.link-mensagem {
  text-decoration: underline;
}

.link-mensagem:hover {
  text-decoration: none;
}

@media screen and (max-width: 767px) {
  .link-mensagem {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 5px;
  }
}
</style>
