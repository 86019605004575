<template>
  <timeline-list style="max-height: 100%; overflow-y: auto" title="Eventos ocorridos">
    <TimelineItemBootstrap v-for="(evento, index) in Eventos" :key="index" :title="evento.name" 
    :description="evento.value == 1 ? 'Problema' : 'Resolvido'" :date-time="evento.clock"
      :color="evento.value == 1 ? 'danger' : 'success'"
      :icon="evento.value == 1 ? 'bi bi-x' : 'bi bi-check'" />
  </timeline-list>
</template>

<script>
import { apimonitoramento } from "../../boot/axios";
import TimelineList from "./TimelineList.vue";
import TimelineItemBootstrap from "./TimelineItemBootstrap.vue";

export default {
  components: {
    TimelineList,
    TimelineItemBootstrap
  },
  props: {
    login: String
  },
  data() {
    return {
      Eventos: [],
    }
  },
  async mounted() {
    await this.BuscaEventos();
  },
  methods: {
    async BuscaEventos() {
      await apimonitoramento
        .post("zabbix/eventos", { PppoeLogin: this.login })
        .then(async (response) => {
          if (response.data.status) {
            this.Eventos = response.data.Eventos;
          }
        })
        .catch((error) => {
          console.log(error)
        })
    }
  },
  watch: {
    async login() {
      await this.BuscaEventos();
    }
  },
}
</script>