<template>
  <div class="card bg-transparent shadow-xl">
    <div class="overflow-hidden position-relative border-radius-xl" :style="{
      backgroundImage:
        'url(' + require('@/assets/img/curved-images/curved14.jpg') + ')',
    }">
      <span class="mask" :class="`bg-gradient-${card.background}`"></span>
      <div class="card-body position-relative z-index-1 p-3">
        <i class="fas fa-wifi text-white p-2" aria-hidden="true"></i>
        <h5 class="text-white mt-4 mb-0" v-if="login.ip">
          {{ login.ip }}
        </h5>
        <h5 v-else>Offline</h5>
        <p class="text-white mb-0" style="font-size: 0.9rem" v-if="login.pd_ipv6">
          {{ login.pd_ipv6 }}
        </p>
        <p class="text-white mb-5" style="font-size: 0.9rem">
          {{ login.login }}
        </p>
        <div class="d-flex">
          <div class="d-flex">
            <div class="me-4">
              <p class="text-white text-sm opacity-8 mb-0">
                Login ID
              </p>
              <h6 class="text-white mb-0">{{ login.id }}</h6>
            </div>
          </div>
          <div class="w-15 d-flex align-items-end justify-content-end"
            :class="$store.state.isRTL ? 'me-auto' : 'ms-auto'">
            <soft-avatar class="w-40 mt-2" :img="logo" alt="logo" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SoftAvatar from "@/components/SoftAvatar.vue";
import img from "@/assets/img/curved-images/curved14.jpg";
import img1 from "@/assets/img/logos/mastercard.png";
import logo from "@/assets/img/logo.png";

export default {
  name: "MasterCard",
  components: {
    SoftAvatar,
  },
  props: {
    login: Object,
    card: {
      background: String,
      default: () => ({
        background: "dark",
      }),
    },
  },
  data() {
    return {
      img,
      img1,
      logo,
    };
  },
};
</script>
